export const userOptions = process.env.GATSBY_ENV === 'production' ? [
  { value: "3352032", label: "Manufacturer" },
  { value: "3352040", label: "Producer" },
  { value: "3352044", label: "Specialty roaster" },
  { value: "3352036", label: "Facilitator" },
  { value: "3352048", label: "Hospitality & Retail" },
  { value: "3352052", label: "End consumer" },
] : [
    { value: "3352060", label: "Manufacturer" },
    { value: "3352132", label: "Producer" },
    { value: "3352128", label: "Specialty roaster" },
    { value: "3352136", label: "Facilitator" },
    { value: "3352124", label: "Hospitality & Retail" },
    { value: "3352144", label: "End consumer" },
  ];
  
  export const defaultOption = process.env.GATSBY_ENV === 'production'
  ? { value: "3352052", label: "End consumer" }
  : { value: "3352144", label: "End consumer" };