import React, { useEffect, useState } from "react"
import FooterLinks from "./footerLinks"
import { Formik } from "formik"
import facebook from "../../../assets/images/icons/facebook.svg"
import twitter from "../../../assets/images/icons/twitter.svg"
import instagram from "../../../assets/images/icons/instagram.svg"
import linkedin from "../../../assets/images/icons/linkedin.svg"
import footerLogo from "../../../assets/images/icons/EoW_Horizontal_logo_TM_transparent_1.svg"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Swal from "sweetalert2"
import  NewsletterDropdown from "../select-dropdown/index"



const Footer = ({ location, pageContext: { translate } }) => {
  const coffeeLabData = translate.coffeeLab
  let tags
  const handleOptionsSelected = (selectedArray) => {
     tags = selectedArray.map(option => option.value)
  };

  const sendSubscribe =  (values, setSubmitting, setFieldValue) => {
   const mailChimpEndpoint = process.env.MAILCHIMP_ENDPOINT
   const merge = process.env.GATSBY_ENV === 'production' ? 'eraofwe' : 'sewnstaging';
    let params = new URLSearchParams();
    params.append('EMAIL', values.email);
    params.append('MERGE6', merge);
    params.append('tags', tags.join(', '));
    const mailChimpUrl = mailChimpEndpoint + '&' + params.toString(); 
    addToMailchimp(values.email,mailChimpUrl)
      .then(data => { 
        if (data.result === "success") { 
          Swal.fire({
            icon: "success",
            text: translate.t?.subscribed_successfully,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })         
        } else if (
          data.result === "error" &&
          data.msg?.includes("already subscribed")
        ) {
          Swal.fire({
            icon: "error",
            text: translate.t?.already_subscribed,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        } else {
          Swal.fire({
            icon: "error",
            text: translate.t?.common_error,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        }
        setSubmitting(false)
        setFieldValue("email", "")
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: translate.t?.common_error,
          confirmButtonColor: "#30855c",
          timer: 3000,
        })
        setSubmitting(false)
      })

  }
  return (
    <div className="footer">
     <div className="card flex justify-content-center">
          
        </div>           
      <div className="content">
        <div className="newsletter">
        <div>
        <h2>{translate.t?.subscribe_to_our_newsletter}</h2>

 <NewsletterDropdown onOptionsSelected={handleOptionsSelected}/>
    </div>
          <div className="email-letter mt-25">
            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {}
                if (!values.email) {
                  errors.email = translate.t?.required
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = translate.t?.invalid_email
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                sendSubscribe(values, setSubmitting, setFieldValue)
                console.log(values)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                    className="input-width rounded"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder={translate.t?.public_whats_your_email}
                    />
                    <div className="error">
                      {errors.email && touched.email && errors.email}
                    </div>
                  </div>
                  <div className="sub-button d-flex flex-column-reverse">
                  <button
                    type="submit"
                    className="btndefault greenbtn "
                    disabled={isSubmitting}
                  >
                    Subscribe
                  </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <FooterLinks pageContext={{ translate }} />
      </div>
      <div className="footer-bottom">
        <div className="flex-div">
          <div className="social-links">
            <a href="https://www.facebook.com/eraofwe" rel="nofollow">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://twitter.com/eraofweofficial" rel="nofollow">
              <img src={twitter} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com/eraofwe/" rel="nofollow">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/eraofwe/" rel="nofollow">
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </div>
          <p>
            &copy;{new Date().getFullYear()} SEWN Technology Solutions AB{" "}
            {translate.t?.all_rights_reserved}
          </p>
        </div>
        <p className="desktop">
          &copy;{new Date().getFullYear()} SEWN Technology Solutions AB{" "}
          {translate.t?.all_rights_reserved}
        </p>

      </div>
      <div className="d-flex flex-column flex-md-row w-100 coffee-lab-footer pb-5 footer-bottom align-items-start">
        <div className="col-12 col-md-10 px-0 px-md-3">
          <h2>The Innovation Lab</h2>
          <div className="row">
            {coffeeLabData?.articles?.map((article, i) => (
              <a
                href={`${process.env.GATSBY_SITE_URL}/coffee-lab${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/en/"
                }articles/${article.slug}`}
                className="col-6 col-md-4 py-2 py-md-0"
                target="_blank"
                key={i}
              >
                {article.title}
              </a>
            ))}
          </div>
        </div>

        <div className="">
          <h2>Categories</h2>
          {coffeeLabData.categories.map((c, i) => (
            <p className="m-0" key={i}>
              <a
                href={`${process.env.GATSBY_SITE_URL}/coffee-lab${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/en/"
                }${c.slug}/articles`}
                target="_blank"
              >
                {c.name}
              </a>
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Footer
  
